<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>OJT/MA Trainee Status</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="3">
                                <b-form-group label-for="search-trainee-status" label="Trainee Status:">
                                    <b-input-group size="sm">
                                        <b-select id="search-trainee-status" v-model="criteria.traineeStatus" :options="traineeStatusOptions">
                                            <template v-slot:first><option :value="null"> - </option></template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.traineeStatus = null"><font-awesome-icon icon="times" /></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="3">
                                <b-form-group label-for="search-trainee-lastname" label="Trainee Last Name:">
                                    <b-input-group size="sm">
                                        <b-input id="search-trainee-lastname" v-model="criteria.lastname" placeholder="Trainee Last Name"/>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.lastname = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="3">
                                <b-form-group label-for="search-trainee-ubcid" label="Trainee UBC ID:">
                                    <b-input-group size="sm">
                                        <b-input id="search-trainee-ubcid"
                                                 v-model="criteria.ubcId"
                                                 placeholder="UBC ID"  />
                                        <b-input-group-addon>
                                            <b-button @click="criteria.ubcId = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="3">
                                <b-form-group label-for="search-trainee-program" label="Trainee Program:">
                                    <b-input-group size="sm">
                                        <b-select id="search-trainee-program"
                                                  v-model="criteria.programs"
                                                  :options="traineeProgramOptions"
                                                  :select-size="8"
                                                  multiple/>
                                        <b-input-group-addon>
                                            <b-button @click="clearProgram"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="3" v-if="!!criteria.programs && criteria.programs.length > 0">
                                <b-form-group label-for="session-date-range" label="Session:">
                                    <picker-of-dates v-model="criteria.sessionRange"
                                                     placeholder="Session Date Range"
                                                     format="M/d/yyyy"
                                                     clearable range/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="3">
                                <b-form-group label-for="search-mentor-name" label="Mentor Last Name:">
                                    <b-input-group size="sm">
                                        <b-input id="search-mentor-name" v-model="mentorNameFilter" placeholder="Mentor Last Name"/>
                                        <b-input-group-addon>
                                            <b-button @click="mentorNameFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="3">
                                <b-form-group label-for="search-contractor" label="Contractor Name:">
                                    <b-input-group size="sm">
                                        <b-input id="search-contractor" v-model="contractorNameFilter" placeholder="Contractor Name"/>
                                        <b-input-group-addon>
                                            <b-button @click="contractorNameFilter = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button class="mr-2" variant="success" @click="getCsv">Export</b-button>
                            <b-button variant="info" @click="clearFilters">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import download from '@/util/downloader';
import UbcId from '@/components/shared/UbcId.vue';
import PickerOfDates from '@/components/shared/PickerOfDates';
import _ from 'underscore';
import {errorModalOptions, errorToastOptions} from "@/util/formatters";

@Component({
    components: {
        UbcId,
        PickerOfDates
    }
})
export default class TraineeStatus extends Vue {

    mentorNameFilter = null;
    contractorNameFilter = null;


    get criteria() {
        return this.$store.getters['users/searchCriteria'];
    }

    get traineeStatusOptions() {
        return _.chain(this.$store.getters['trainees/statuses'])
                .reject((o) => ['Applied','Declined'].includes(o.status))
                .map((o) => {
                    return {
                        value: o.id,
                        text: o.status
                    };
                })
                .value();
    }

    get traineeProgramOptions() {
        return [
            'Trainee Program 1',
            'Trainee Program 2',
            'Trainee Program 3',
            'Trainee Program 4'
        ];
    }

    clearProgram() {
        this.criteria.programs = [];
        this.criteria.session = null;
    }

    clearFilters() {
        this.criteria.clear();
        this.mentorNameFilter = null;
        this.contractorNameFilter = null;
    }

    async getCsv() {
        const criteria = this.criteria.serialize();
        try {
            await download('POST','/api/v1/export/trainee-status', JSON.stringify({
                mentorLastName: this.mentorNameFilter,
                contractorName: this.contractorNameFilter,
                searchCriteria: criteria
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('OJT/MA Trainee Status', null, true));
        _.each([
            'trainees/loadTraineeStatuses'//,
            //'tracks/loadPrograms'
        ], (action) => {
            this.$store.dispatch(action)
                .catch(error => {
                    this.$bvToast.toast(error.message, errorToastOptions);
                });
        });
        this.clearFilters();
    }
}
</script>